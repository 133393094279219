import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lesson"
export default class extends Controller {
  connect() {
    $('#popup').modal('show')
  }

  async hidePopup(e) {
    const courseId = e.currentTarget.id.split("-")[1]
    const res = await fetch(`/user_course_popups?course_id=${courseId}`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })

    JSON.parse(await res.text());

    $('#popup').modal('hide')
  }

  async newLesson(e) {
    const divParent = e.currentTarget.parentElement
    const id = divParent.id.split("-")[1]

    let res = await fetch(`/lessons/${id}`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })

    const lesson = JSON.parse(await res.text());
    const medias = lesson.medias
    const description = lesson.description
    const videos = lesson.videos

    this.configurateLesson(lesson)
    this.renderDescription(description)

    if (lesson.released) {
      this.renderMaterial(medias)
      this.renderVideos(videos)
      this.renderAreaVideo(lesson, videos)
    } else {
      this.renderMaterial([])
      this.renderVideos([])
      this.renderAreaVideo(lesson, [])
      this.timerReleased(lesson)
    }
  }

  timerReleased(lesson) {
    clearInterval(this.intervalo)
    const nowLesson = document.querySelector("#now-lesson")

    const novaDivHTML = `
      <div class="timer position-absolute w-100 h-100 d-flex justify-content-center flex-column align-items-center">
        <h3>Aula liberada em breve</h3>
        <div class="timer-container">
          <div class="timer-section">
              <span class="time-days">0</span>
              <label>Dias</label>
          </div>
          <div class="timer-section">
              <span class="time-hours">0</span>
              <label>Horas</label>
          </div>
          <div class="timer-section">
              <span class="time-minutes">0</span>
              <label>Minutos</label>
          </div>
          <div class="timer-section">
              <span class="time-seconds">0</span>
              <label>Segundos</label>
          </div>
        </div>
      </div>
    `

    nowLesson.insertAdjacentHTML('beforeend', novaDivHTML);

    this.intervalo = setInterval(() => {
      const release_date = lesson.release_date
      const dataInicio = new Date();
      const dataFinal = new Date(release_date);

      const diff = Math.abs(dataFinal - dataInicio);
      const segundos = Math.floor(diff / 1000) % 60;
      const minutos = Math.floor(diff / (1000 * 60)) % 60;
      const horas = Math.floor(diff / (1000 * 60 * 60)) % 24;
      const dias = Math.floor(diff / (1000 * 60 * 60 * 24));

      document.querySelector(".time-days").innerHTML = dias < 10 ? `0${dias}` : dias;
      document.querySelector(".time-hours").innerHTML = horas < 10 ? `0${horas}` : horas;
      document.querySelector(".time-minutes").innerHTML = minutos < 10 ? `0${minutos}` : minutos;
      document.querySelector(".time-seconds").innerHTML = segundos < 10 ? `0${segundos}` : segundos;
    }, 1000);
  }

  configurateLesson(lesson) {
    const titleLessonCurrent = document.querySelector(".title-lesson-current")
    titleLessonCurrent.innerHTML = lesson.title

    const lessonOtherActive = document.querySelectorAll('.about-lesson-other.active')
    lessonOtherActive.forEach(element => element.classList.remove('active'));

    const lessonInCapsule = document.querySelector(`#lesson-${lesson.id}`)
    lessonInCapsule.classList.add("active");

    this.renderButtonConclued(lesson.attended)
  }

  async completedVideo() {
    const nowLesson = document.querySelector("#now-lesson")
    const dataLesson = nowLesson.dataset.lesson
    const dataVideo = nowLesson.dataset.video

    const lessonId = dataLesson.split("-")[1]
    let url = ""

    if (dataVideo && dataVideo.split("-")[1] != "0") {
      const videoId = dataVideo.split("-")[1]
      url = `/lessons/${lessonId}/video/${videoId}/user_attended_video`

      const currentVideo = document.querySelector(`#video-${videoId}`)
      currentVideo.setAttribute("data-attended", true);

      this.changeCompletedVideoIcon(videoId, true)

      const completeLesson = this.verifyCompletedCourse()
      this.changeCompletedLessonIcon(lessonId, completeLesson)
    } else {
      url = `/lessons/${lessonId}/user_attended_lesson`
      this.changeCompletedLessonIcon(lessonId, true)
    }

    await fetch(url, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })

    this.renderButtonConclued(true)
  }

  async descompletedVideo() {
    const nowLesson = document.querySelector("#now-lesson")
    const dataLesson = nowLesson.dataset.lesson
    const dataVideo = nowLesson.dataset.video

    const lessonId = dataLesson.split("-")[1]
    let url = ""

    if (dataVideo && dataVideo.split("-")[1] != "0") {
      const videoId = dataVideo.split("-")[1]
      url = `/lessons/${lessonId}/video/${videoId}/user_not_attended_video`

      const currentVideo = document.querySelector(`#video-${videoId}`)
      currentVideo.setAttribute("data-attended", false);

      this.changeCompletedVideoIcon(videoId, false)
      this.changeCompletedLessonIcon(lessonId, false)
    } else {
      url = `/lessons/${lessonId}/user_not_attended_lesson`
      this.changeCompletedLessonIcon(lessonId, false)
    }

    await fetch(url, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })

    this.renderButtonConclued(false)
  }

  async changeVideo(e, step = false) {
    let id;
    let title;
    let link;
    let attended;

    if (step) {
      id = e.id.split("-")[1]
      title = e.dataset.title
      link = e.dataset.link
      attended = (e.dataset.attended === "true")
    } else {
      id = e.currentTarget.id.split("-")[1]
      title = e.currentTarget.dataset.title
      link = e.currentTarget.dataset.link
      attended = (e.currentTarget.dataset.attended === "true")
    }

    const video = {
      id: id,
      title: title,
      link: link,
      attended: attended
    }

    await this.openLessonLectureVideo(video)

    const titlVideoCurrent = document.querySelector(".title-video-current")

    const nowLesson = document.querySelector("#now-lesson")
    nowLesson.setAttribute("data-video", `now-${id}`);

    const videoOtherActive = document.querySelectorAll('.about-video .title.active')
    videoOtherActive.forEach(element => element.classList.remove('active'));

    const currentVideo = document.querySelector(`#video-${id} .title`)
    currentVideo.classList.add("active");

    this.renderVideo(titlVideoCurrent, nowLesson, video)
  }

  changeCompletedLessonIcon(lessonId, completed) {
    const lessonCompletedIcon = document.querySelector(`#lesson-${lessonId} .lesson-completed .icon`)
    if (completed) {
      lessonCompletedIcon.classList.add("active");
    } else {
      lessonCompletedIcon.classList.remove('active')
    }
  }

  changeCompletedVideoIcon(videoId, completed) {
    const videoCompletedIcon = document.querySelector(`#video-${videoId} .icon-video-completed`)
    const statusCompletedIcon = document.querySelector(`#video-${videoId} .status`)
    if (completed) {
      videoCompletedIcon.classList.add("active");
      statusCompletedIcon.innerHTML = "Assistido"
    } else {
      videoCompletedIcon.classList.remove('active')
      statusCompletedIcon.innerHTML = "Não Assistido"
    }
  }

  verifyCompletedCourse() {
    const totalVideo = document.querySelectorAll('.videos > div').length
    const totalVideoAttended = document.querySelectorAll('[data-attended="true"]').length
    return totalVideo === totalVideoAttended
  }

  renderAreaVideo(lesson, videos) {
    const titlVideoCurrent = document.querySelector(".title-video-current")
    titlVideoCurrent.innerHTML = " "

    const nowLesson = document.querySelector("#now-lesson")
    nowLesson.setAttribute("data-lesson", `now-${lesson.id}`);
    nowLesson.removeAttribute("data-video")
    nowLesson.innerHTML = " "

    if (videos.length > 0) {
      const video = videos[0]
      nowLesson.setAttribute("data-video", `now-${video.id}`);
      this.renderVideo(titlVideoCurrent, nowLesson, video)
      this.heightAreaContentVideos(videos)
      return
    }

    nowLesson.innerHTML = `<img class="img-fluid" alt="${lesson.course_title}" src="${lesson.course_image}">`
  }

  renderMaterial(medias) {
    const materials = document.querySelector(".materials")
    materials.innerHTML = " "

    medias.forEach(media => {
      const material = `
        <div class="col-lg-6 col-md-12">
          <div class="material">
            <div class="d-flex align-items-center area-download">
              <div class="material-title">${this.titleMaterial(media.name)}</div>
              <div class="material-download d-flex align-items-center">
                <button class="btn btn-material-download" data-id="${media.id}" data-file="${media.file}" data-action="click->lesson#openMaterial">
                  <i class="fa fa-download" aria-hidden="true"></i>
                  BAIXE AGORA
                </button>
              </div>
            </div>
            ${this.formatMaterial(media.name, media.file)}
          </div>
        </div>
      `

      materials.innerHTML += material
    });
  }

  renderDescription(description) {
    const descriptions = document.querySelector("#description")
    descriptions.innerHTML = " "

    if (description) {
      descriptions.innerHTML = `
        <div class="mt-4 description">
          ${description}
        </div>
      `
    }
  }

  renderVideos(videos) {
    const divVideo = document.querySelector(".videos")
    divVideo.innerHTML = " "

    videos.forEach((video, i) => {
      let statusFisrtVideo = (i == 0 ? "active" : "")
      let videoConclued = (video.attended ? "active" : "")
      let textVideo = (video.attended ? "Assistido" : "Não Assistido")
      let statusVideo = ""
      if (video.womb) {
        statusVideo = `
        <div class="icon-video-completed font-weight-bold ${videoConclued}">
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </div>
          <div class="status font-weight-bold">${textVideo}</div>
        `
      }

      divVideo.innerHTML += `
        <div class="col-lg-3 col-md-3 col-sm-4 col-6 p-0 itens">
          <div id="video-${video.id}" class="about-video" data-title="${video.title}" data-link="${video.link}" data-attended="${video.attended}" data-action="click->lesson#changeVideo">
            <div class="area-content d-flex align-items-center">
              <div class="number">
                <div>${i + 1}</div>
              </div>
              <div>
                <div class="title mb-1 ${statusFisrtVideo}">${video.title}</div>
                <div class="d-flex align-items-center">
                  ${statusVideo}
                </div>
              </div>
            </div>
          </div>
        </div>
      `
    });
  }

  renderVideo(titlVideoCurrent, nowLesson, video) {
    this.renderButtonConclued(video.attended)

    titlVideoCurrent.innerHTML = video.title

    const link = this.codeUrl(video.link)

    nowLesson.innerHTML = `
      <div class="iframe-container">
        <iframe src="${link}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    `
  }

  renderButtonConclued(attended) {
    const areaBtnCompleted = document.querySelector(".area-btn-completed")
    let action = ""
    let text = ""

    if (attended) {
      action = "click->lesson#descompletedVideo"
      text = "Desmarcar"
    } else {
      action = "click->lesson#completedVideo"
      text = "Marcar"
    }

    areaBtnCompleted.innerHTML = `
      <button class="btn btn-completed-video" data-action="${action}">
      ${text} como concluída
      </button>
    `
  }

  codeUrl(url) {
    if (url.includes("youtube")) {
      let code;
      try {
        code = url.match(/youtube.com.*(?:\/|v=)([^&$]+)/)[1];
      } catch (e) {
        code = "0RptxXLf_FA";
      }
      return `https://www.youtube.com/embed/${code}`;
    }

    return url;
  }

  titleMaterial(name) {
    const [title, format] = name.split('.')
    return `${format}: ${title}`
  }

  formatMaterial(name, file) {
    const [title, format] = name.split('.')

    if (format == "mp3") {
      return `
        <div class="mt-3">
          <audio controls>
            <source src="${file}" type="audio/mpeg">
            Your browser does not support the audio element.
          </audio>
        </div>
      `
    }

    return ""
  }

  async completedLesson(e) {
    const divParent = e.currentTarget.parentElement
    const lessonId = divParent.id.split("-")[1]
    let url = "";

    const lessonCompletedIcon = document.querySelector(`#lesson-${lessonId} .lesson-completed .icon`)
    if (lessonCompletedIcon.className.includes("active")) {
      url = `/lessons/${lessonId}/user_not_attended_lesson`
      this.changeCompletedLessonIcon(lessonId, false)
      this.renderButtonConclued(false)
    } else {
      url = `/lessons/${lessonId}/user_attended_lesson`
      this.changeCompletedLessonIcon(lessonId, true)
      this.renderButtonConclued(true)
    }

    await fetch(url, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })

    await this.changeProgressiveCapsule(lessonId)
  }

  async changeProgressiveCapsule(lessonId) {
    const res = await fetch(`/lessons/${lessonId}/capsule`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })

    const capsule = JSON.parse(await res.text());

    const progressiveCapsule = document.querySelector(`#progressive-capsule-${capsule.id}`)
    progressiveCapsule.innerHTML = ""

    if (capsule.progressive == 100) {
      progressiveCapsule.innerHTML = `
        <div class='icon-capsule-completed font-weight-bold'>
          <i class='fa fa-check' aria-hidden='true'></i>
        </div>
        <div class='status font-weight-bold'>Completo</div>
      `

      return
    }

    progressiveCapsule.innerHTML = `
      <div class='porcentage font-weight-bold'>${capsule.progressive}%</div>
      <div class='bar font-weight-bold'>
        <div class='progressive-bar'>
          <div style='width: ${capsule.progressive}%;'></div>
        </div>
      </div>
    `
  }

  heightAreaContentVideos(videos) {
    let maxLenghTitle = 0
    videos.forEach(video => {
      const lenghTitle = video.title.length
      if (lenghTitle > maxLenghTitle) maxLenghTitle = lenghTitle
    })

    const areaContents = document.querySelectorAll(".area-content");
    areaContents.forEach(areaContent => {
      if (maxLenghTitle > 31 && maxLenghTitle < 60) {
        areaContent.style.height = "100px";
      } else if (maxLenghTitle > 60) {
        areaContent.style.height = "110px";
      }
    });
  }

  async openLessonLectureVideo(video) {
    const nowLesson = document.querySelector("#now-lesson")
    const dataLesson = nowLesson.dataset.lesson
    const lessonId = dataLesson.split("-")[1]

    const res = await fetch(`/lessons/${lessonId}/open_lesson_lecture_video?video_id=${video.id}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })

    JSON.parse(await res.text());
  }

  async openMaterial(e) {
    try {
      const button = e.target.closest('button.btn-material-download');
      const media_file = button.dataset.file;
      const media_id = button.dataset.id;

      const nowLesson = document.querySelector("#now-lesson");
      const dataLesson = nowLesson.dataset.lesson;
      const lessonId = dataLesson.split("-")[1];

      const res = await fetch(`/lessons/${lessonId}/download_material_lesson?media_id=${media_id}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      });

      if (!res.ok) {
        throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
      }

      await res.json();

      window.open(media_file, '_blank');
    } catch (error) {
      console.error('Error opening material:', error);
      alert('Ocorreu um problema ao abrir o material. Por favor, tente novamente.');
    }
  }

  disconnect() {
    // Limpar o intervalo quando o controller for desconectado
    clearInterval(this.intervalo);
  }
}
