import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="login"
export default class extends Controller {
  connect() {
    $('#cpf').mask('000.000.000-00');
    $('#posta_code').mask('00000-000');
    $('#phone').mask('(00) 00000-0000');

    const msgError = document.querySelector("#msg-error").innerHTML

    if (msgError.length > 0) {
      swal('Erro', `${msgError}`, 'error')
    }
  }

  async viacep(event) {
    const cep = event.target.value

    if (cep.length === 9) {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      })

      const data = await response.json();
      if (!data.erro) {
        document.querySelector("#street").value = data.logradouro
        document.querySelector("#neighborhood").value = data.bairro
        document.querySelector("#city").value = data.localidade
        document.querySelector("#uf").value = data.uf
      }
    } else {
      document.querySelector("#street").value = ""
      document.querySelector("#number").value = ""
      document.querySelector("#neighborhood").value = ""
      document.querySelector("#city").value = ""
      document.querySelector("#uf").value = ""
    }
  }

  cadastro(event) {
    event.preventDefault()
    document.querySelector("#btn-submit").disabled = true

    const password = document.querySelector("#password").value
    const cpassword = document.querySelector("#cpassword").value

    if (cpassword != password) {
      swal('Erro', `Senha e Confirmação de senha devem ser iguais`, 'error')
      document.querySelector("#btn-submit").disabled = false
      return
    }

    event.target.submit()
  }

  async forgotPassoword(event) {
    event.preventDefault()

    const email = document.querySelector("#email").value

    const response = await fetch(`/login/validate_forgot_password`, {
      method: 'POST',
      body: JSON.stringify({ email: email }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })

    const data = await response.json();

    if (response.status == 200) {
      swal('Sucesso', `${data.messages}`, 'success')
    } else {
      swal('Erro', `${data.messages}`, 'error')
    }
  }
}
