import consumer from "./consumer"

consumer.subscriptions.create("ChatChannel", {
  connected() {
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const chat = document.querySelector(`[data-channel='chat-${data.id}']`);
    if (!chat) { return }

    setTimeout(() => this.renderTempMessage(chat, data), 4000);
  },

  formattedText(text) {
    const container = document.createElement('div'); // Container principal para o texto formatado

    const lines = text.split('\n');
    lines.forEach((line, lineIndex) => {
      const lineDiv = document.createElement('div'); // Cria um <div> para cada linha

      // Dividindo a linha em segmentos com base em negrito
      line.split(/(\*\*[^*]+\*\*)/)
        .filter(Boolean) // Remove strings vazias
        .forEach((segment, segmentIndex) => {
          if (segment.startsWith('**') && segment.endsWith('**')) {
            const bold = document.createElement('b'); // Cria elemento <b> para texto em negrito
            bold.textContent = segment.slice(2, -2); // Remove os marcadores '**'
            lineDiv.appendChild(bold); // Adiciona ao div da linha
          } else {
            const span = document.createElement('span'); // Cria elemento <span> para texto normal
            span.textContent = segment;
            lineDiv.appendChild(span); // Adiciona ao div da linha
          }
        });

      container.appendChild(lineDiv); // Adiciona o div da linha ao container principal

      if (lineIndex < lines.length - 1) {
        container.appendChild(document.createElement('br')); // Adiciona uma quebra de linha se não for a última linha
      }
    });

    return container; // Retorna o container com todo o texto formatado
  },

  renderTempMessage(chat, data) {
    const messageTemporaria = chat.querySelector('#message-temporaria');
    if (messageTemporaria) {
      messageTemporaria.remove()
    }

    const container = this.formattedText(data.message.answer)

    // Crie um elemento HTML e insira 'container' como um child node, não como uma string
    const messageElement = document.createElement('div');
    messageElement.className = "message mt-5 mb-4";
    messageElement.innerHTML = `
        <div class="d-flex align-items-center mb-3">
          <div><img src="/assets/images/ravi/ravi.jpeg" class="img-fluid avatar-50 rounded-circle" alt="ravi"></div>
          <img src="/assets/images/ravi/ravi-title.png" class="img-fluid ml-2" alt="ravi" style="width: 100px;">
        </div>
      `;

    const contentDiv = document.createElement('div');
    contentDiv.className = "px-2";
    contentDiv.appendChild(container);  // Inserir 'container' como um elemento

    messageElement.appendChild(contentDiv);
    chat.appendChild(messageElement);  // Adicione 'messageElement' ao 'chat'

    chat.scrollTop = chat.scrollHeight;
  }
});
