import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="home"
export default class extends Controller {
  connect() {
    $('#anuncio').modal('show')
  }

  async desfavoriteCourse(e) {
    const ulPlayLists = e.currentTarget
    const courseId = ulPlayLists.id.split('-')[1]
    const res = await fetch(`/courses/${courseId}/apply_favorite_course`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })

    const data = JSON.parse(await res.text());
    console.log('data :>> ', data.message);
    location.reload()
  }


  async hideAdvertisement() {
    const res = await fetch(`/user_advertisements`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })

    JSON.parse(await res.text());

    $('#anuncio').modal('hide')
  }
}
