import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="capsule"
export default class extends Controller {
  accordionTrigger() {
    const totalVideo = document.querySelector(".total-video");
    const areaListContentCourse = document.querySelector(".area-list-content-course");
    const allContents = document.querySelector(".all-contents ")

    if (areaListContentCourse.classList.contains("hidden")) {
      areaListContentCourse.classList.remove("hidden");
      totalVideo.classList.remove("col-lg-12");
      totalVideo.classList.add("col-lg-8");
      allContents.style.display = "none";
    } else {
      areaListContentCourse.classList.add("hidden");
      totalVideo.classList.remove("col-lg-8");
      totalVideo.classList.add("col-lg-12");
      allContents.style.display = "flex";
    }
  }
}
