import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="course"
export default class extends Controller {
  connect() {
  }

  async favoriteCourse(e) {
    const ulPlayLists = e.currentTarget
    const divToltip = ulPlayLists.parentNode;
    const liFavorite = ulPlayLists.querySelector("li")

    if (liFavorite.classList.length === 0) {
      liFavorite.classList.add("active");
      divToltip.setAttribute("title", "Desfavoritar")
    } else {
      liFavorite.classList.remove("active");
      divToltip.setAttribute("title", "Favoritar")
    }

    const courseId = ulPlayLists.id.split('-')[1]
    const res = await fetch(`/courses/${courseId}/apply_favorite_course`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })

    const data = JSON.parse(await res.text());
    console.log('data :>> ', data.message);
  }
}
