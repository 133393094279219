import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="step-lesson"
export default class extends Controller {
  nextVideo() {
    const currentLesson = document.querySelector(".about-lesson-other.active");
    const capsulesContainer = document.querySelector(".capsules");
    let nextLesson = currentLesson.nextElementSibling;

    capsulesContainer.scrollTop += 20;

    if (!nextLesson || !nextLesson.classList.contains("about-lesson-other")) {
      let currentCardBody = currentLesson.closest(".card-body");
      let nextCard = currentCardBody.closest(".card").nextElementSibling;

      while (nextCard) {
        let nextCardHeader = nextCard.querySelector("[data-toggle='collapse']");
        let nextCardBody = nextCard.querySelector(".card-body");
        let firstLessonInNextCard = nextCardBody ? nextCardBody.querySelector(".about-lesson-other") : null;

        if (firstLessonInNextCard) {
          nextCardHeader.click();

          currentLesson.classList.remove("active");
          firstLessonInNextCard.classList.add("active");

          firstLessonInNextCard.querySelector("[data-action='click->lesson#newLesson']").click();
          return;
        } else {
          nextCard = nextCard.nextElementSibling;
        }
      }
    } else {
      currentLesson.classList.remove("active");
      nextLesson.classList.add("active");

      nextLesson.querySelector("[data-action='click->lesson#newLesson']").click();
    }
  }


  previousVideo() {
    const currentLesson = document.querySelector(".about-lesson-other.active");
    let previousLesson = currentLesson.previousElementSibling;

    const capsulesContainer = document.querySelector(".capsules");
    capsulesContainer.scrollTop -= 20;


    if (!previousLesson || !previousLesson.classList.contains("about-lesson-other")) {
      let currentCardBody = currentLesson.closest(".card-body");
      let previousCard = currentCardBody.closest(".card").previousElementSibling;

      while (previousCard) {
        let previousCardBody = previousCard.querySelector(".card-body");
        let lastLessonInPreviousCard = previousCardBody ? previousCardBody.querySelector(".about-lesson-other:last-child") : null;

        if (lastLessonInPreviousCard) {
          let previousCardHeader = previousCard.querySelector("[data-toggle='collapse']");

          previousCardHeader.click();

          currentLesson.classList.remove("active");
          lastLessonInPreviousCard.classList.add("active");

          lastLessonInPreviousCard.querySelector("[data-action='click->lesson#newLesson']").click();
          return;
        } else {
          previousCard = previousCard.previousElementSibling;
        }
      }
    } else {
      currentLesson.classList.remove("active");
      previousLesson.classList.add("active");

      previousLesson.querySelector("[data-action='click->lesson#newLesson']").click();
    }
  }
}
