import { Controller } from "@hotwired/stimulus"
import { jsonToString } from "webpack/lib/Stats";

// Connects to data-controller="ravi"
export default class extends Controller {
  connect() {
    this.chat = document.querySelector(".chat");
    this.informativoInicial = document.querySelector(".informativo-inicial");
    this.btnSend = document.querySelector(".btn-send")

    this.scrollChat()
  }

  openComoUsar() {
    window.open("https://www.youtube.com/watch?v=PeSKW2BlLxc", "_target")
  }

  scrollChat() {
    this.chat.scrollTop = this.chat.scrollHeight;
  }

  async reportError(event) {
    event.preventDefault()

    const form = event.target
    const res = await fetch(`/ravis/report_error`, {
      method: 'POST',
      body: new FormData(form)
    })

    const response = await res.json();

    $('#reportarErro').modal('hide');
    Array.from(form.elements).forEach(element => {
      element.value = '';
    });

    swal('Sucesso', `${response.message}`, 'success')
  }

  async handleSendMessage() {
    const newMessage = document.querySelector("#textarea-message")
    this.btnSend.disabled = true

    if (newMessage.length < 5) {
      this.renderError("Digite um mensagem valida")
      return
    }

    const res = await fetch(`/ravis`, {
      method: 'POST',
      body: JSON.stringify({ question: newMessage.value }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })

    const data = JSON.parse(await res.text());
    if (data.message) {
      this.renderError(data.message)
      return
    }

    if (this.informativoInicial) {
      this.informativoInicial.style.display = 'none'
    }

    this.renderMessage(newMessage.value)
    newMessage.value = ""

    this.btnSend.disabled = false
    this.scrollChat()
  }

  formattedText(text) {
    const container = document.createElement('div'); // Container principal para o texto formatado

    const lines = text.split('\n');
    lines.forEach((line, lineIndex) => {
      const lineDiv = document.createElement('div'); // Cria um <div> para cada linha

      // Dividindo a linha em segmentos com base em negrito
      line.split(/(\*\*[^*]+\*\*)/)
        .filter(Boolean) // Remove strings vazias
        .forEach((segment, segmentIndex) => {
          if (segment.startsWith('**') && segment.endsWith('**')) {
            const bold = document.createElement('b'); // Cria elemento <b> para texto em negrito
            bold.textContent = segment.slice(2, -2); // Remove os marcadores '**'
            lineDiv.appendChild(bold); // Adiciona ao div da linha
          } else {
            const span = document.createElement('span'); // Cria elemento <span> para texto normal
            span.textContent = segment;
            lineDiv.appendChild(span); // Adiciona ao div da linha
          }
        });

      container.appendChild(lineDiv); // Adiciona o div da linha ao container principal

      if (lineIndex < lines.length - 1) {
        container.appendChild(document.createElement('br')); // Adiciona uma quebra de linha se não for a última linha
      }
    });

    return container; // Retorna o container com todo o texto formatado
  }

  renderMessage(message) {
    const nome = document.querySelector(".chat").dataset.chatName
    const container = this.formattedText(message)
    const messageElement = document.createElement('div');
    messageElement.className = "message mt-5 mb-4";
    messageElement.innerHTML = `
      <div class="d-flex align-items-center mb-3">
        <div><img src="/assets/images/ravi/aluno.jpeg" class="img-fluid avatar-50 rounded-circle" alt="aluno"></div>
        <h4 class="ml-2">${nome}</h4>
      </div>
    `;

    const contentDiv = document.createElement('div');
    contentDiv.className = "px-2";
    contentDiv.appendChild(container);

    messageElement.appendChild(contentDiv);
    this.chat.appendChild(messageElement);

    setTimeout(this.renderTempMessage, 2000);
  }

  renderTempMessage() {
    const chat = document.querySelector(".chat");
    chat.innerHTML += `
      <div class="message mt-5" id="message-temporaria" style="margin-bottom: 80px !important;">
        <div class="d-flex align-items-center mb-3">
          <div><img src="/assets/images/ravi/ravi.jpeg" class="img-fluid avatar-50 rounded-circle" alt="ravi"></div>
          <img src="/assets/images/ravi/ravi-title.png" class="img-fluid ml-2" alt="ravi" style="width: 100px;">
        </div>
        <img src="/assets/images/ravi/loader-botao.gif" class="img-fluid ml-2 loader-botao" alt="loader-botao">
      </div>
    `

    chat.scrollTop = chat.scrollHeight;
  }

  renderError(message) {
    swal('Erro', `${message}`, 'error')
    this.btnSend.disabled = false
  }
}
